@font-face {
    font-family: "Charles Wright";
    src: url("./fonts/CharlesWright-Bold.ttf") format("truetype");
}

/* ** apply the base font styling to the entire site ** */
* {
    
}

body {
    -webkit-font-smoothing: antialiased;
}

strong {
    font-weight: 500;
}