/* ### DEFAULT VALUES - XS ### */

.radio-buttons .btn {
    padding: 1rem 0 1rem 0;
    border-radius: 5px;
    width: 100%;
    flex: 0 0 auto;
    margin-bottom: 0.5rem;
}

.radio-buttons > .btn-check + .btn:not(:last-of-type) {
    margin-right: 0.5rem;
}

.radio-buttons.stackable {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}
.radio-buttons.two-buttons {
    width: 100%;
}
.radio-buttons.two-buttons > .btn-check + .btn {
    width: 100%;
    max-width: 48%;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {

    .radio-buttons > .btn-check + .btn:nth-of-type(odd) {
        margin-right: 0.5rem;
    }

    .radio-buttons > .btn-check + .btn {
        max-width: 48%;
    }
    
    .radio-buttons.stackable {
        display: block;
    }
    
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

    .radio-buttons > .btn-check + .btn {
        width: 48%;
    }
        
    .radio-buttons.stackable {
        flex-direction: row;
        display: inline-flex;
        width: 100%;
        align-items: stretch;
        justify-content: flex-start;
    }    
    .radio-buttons.stackable > .btn-check + .btn,
    .radio-buttons.stackable > .btn {
        position: relative;
        flex: 1 1 auto;
        width: auto;
        margin-bottom: auto;
    }
    .radio-buttons.stackable > .btn:not(:last-child),
    .radio-buttons.stackable > .btn-check + .btn:not(:last-child) {
        margin-right: 0.25rem;
    }

    .radio-buttons.two-buttons {
        max-width: 80%;
    }
    .radio-buttons.two-buttons > .btn-check + .btn {
        width: 100%;
        max-width: 48%;
        margin-bottom: auto;
    }
    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .radio-buttons.two-buttons {
        max-width: 50%;
    }
    
    .radio-buttons.two-buttons > .btn-check + .btn {
        max-width: 48%;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
