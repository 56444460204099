/* ### DEFAULT VALUES - XS ### */

.error-page {
}
.error-page .site-name {
    display: none;
}

.error-page .header-container {
    
}

.error-page .header-container .header-text {
    text-align: left;
    padding-top: 1rem;
}

.error-page .header-text .icon-header > div:first-of-type {
    width: 100px;
}

.error-page .header-text .icon {
    font-size: 4rem;
}
.error-page .header-text .text {
    font-weight: bold;
}

.error-page .panel .card-body > div {
    margin-bottom: 1rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .error-page .header-text .icon-header > div:first-of-type {
        width: 120px;
    }
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .error-page .header-container {
    }
    .error-page .panel {
        padding-bottom: 2rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
