/* ### DEFAULT VALUES - XS ### */

.date-picker .MuiPickersLayout-contentWrapper .Mui-selected:not(.Mui-disabled) {
    background-color: #3ea736;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
