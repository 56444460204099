/*
  * Colours
    * Purple: #5d257c / 93, 37, 124
    * Green: #3EA736 / 62, 167, 54

  * Continue Buttons: btn-primary
  * Back Buttons: btn-outline-dark
  * Radio Buttons: btn-outline-secondary
*/

:root {
    --bs-link-color: #3EA736;
    --bs-link-color-rgb: 62, 167, 54;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #3EA736;
    --bs-link-hover-color-rgb: 62, 167, 54;
}