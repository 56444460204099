/* ### DEFAULT VALUES - XS ### */

.form-group {
    
}

.form-label {
    font-weight: 500;
    margin-bottom: 0.75rem;
    font-size: 1.1rem;
}
.form-label.label-style-2 {
    font-weight: 300;
    font-size: 1rem;
}
.form-label.has-help-text {
    margin-bottom: 0;
}

.form-control {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

.help-text {
    margin-bottom: 0.75rem;
    font-weight: 400;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    
    .form-label {
        font-size: 1.2rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
    
}
