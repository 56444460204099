/* ### DEFAULT VALUES - XS ### */

.cookie-consent.cookie-consent-fw {
    background-color: black;
    color: white;
    padding: 1rem 0;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .cookie-consent.cookie-consent-fw .consent-btn {
        padding-left: 1.5rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}