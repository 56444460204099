/* ### DEFAULT VALUES - XS ### */

.date-picker .MuiPickersLayout-contentWrapper {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 0.375rem;
}

/* Reset the colours where the selected day is not available */
.date-picker .MuiPickersLayout-contentWrapper .Mui-selected.Mui-disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.38);
    opacity: 1;
}
.date-picker .MuiPickersLayout-contentWrapper .MuiPickersDay-today:not(.Mui-disabled) {
    
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
