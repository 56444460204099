/* ### DEFAULT VALUES - XS ### */

.has-error .form-label {
    color: red;
}

.has-error .radio-buttons .btn {
    border-color: red;
}

.has-error .form-control {
    border-color: red;
}
.has-error .react-select div[class*="-control"] {
    border-color: red;
}
.has-error .react-select svg {
    color: red;
}
.has-error .error-text {
    color: red;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
