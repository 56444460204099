/* ### DEFAULT VALUES - XS ### */

.form-label {
    color: #666666;
}
.form-label.label-style-2 {
    color: #000;
}

.help-text {
    color: #17A2B8;
}

/* TEXT / SELECT */
.form-control {
    border-color: #ccc;
}

.form-control:focus {
    border-color: #ccc;
    box-shadow: 0 -3px 3px -2px #5d257c inset;
}

.input-group:focus-within {
    border-radius: 0.375rem;
    box-shadow: 0 -3px 3px -2px #5d257c inset;
}

.input-group:focus-within input {
    /*box-shadow: none;*/
}

.input-group-text {
    background-color: #EEE9F2;
    border-color: #ccc;
}

/* SEARCH SELECT */

.react-select div[class*="-control"] {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: none;
}

.react-select div[class*="-control"]:hover {
    border-color: #ccc;
    box-shadow: none;
}

.react-select:not(.danger-text) div[class*="-control"]:focus-within {
    border-color: #ccc;
    box-shadow: 0 -3px 3px -2px #5d257c inset;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
