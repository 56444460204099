/* ### DEFAULT VALUES - XS ### */

.alert-success {
    --bs-alert-color: var(--bs-success-text-emphasis);
    --bs-alert-bg: #E9F8E6;
    --bs-alert-border-color: #3EA736;
    --bs-alert-link-color: var(--bs-success-text-emphasis);
    --bs-alert-padding-y: 0.25rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .alert-success {
        --bs-alert-padding-x: 3rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}