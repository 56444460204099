/* ### DEFAULT VALUES - XS ### */

.vehicle-registration {
    padding: 0rem 0.7rem;
    font-size: 1.9em;
    letter-spacing: 1.5px;
    border: 2px solid #000000 !important;
    color: #000000;
    font-family: Charles Wright;
    border-radius: 0.375rem;
    background-color: #efefef;
}
/* 
  For when the page has been translated via Google Chrome
  which adds <font> tags around the translated content
*/
.vehicle-registration font {
    font-family: Charles Wright;
}

.vehicle-description {
    font-weight: 600;
    font-size: 1.15rem;
}
.vehicle-found-value {
    margin-top: 0.5rem;
}
.vehicle-found-value + .help-text {
    margin-bottom: 0;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .vehicle-description {
        font-weight: 600;
        font-size: 1.5rem;
        padding-right: 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
