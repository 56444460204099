/* ### DEFAULT VALUES - XS ### */


/* GENERAL */

/*
examples of "quote" and "buy"
.layout-container.layout-car-nb.layout-quote .layout-header 
.layout-container.layout-car-nb.layout-buy .layout-header 
*/

/*
OTHER
*/
.agg-logo img {
	max-height: 45px;
	max-width: 100%;
	height: auto;
}

.agg-logo .logo {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.agg-logo .logo img {
	max-height: 45px;
	max-width: 100%;
	height: auto;
}

.agg-logo.ctm .logo {
	padding-bottom: 0.25rem;
}

.agg-logo.ctm .logo img,
.agg-logo.comparethemarket img {
	max-height: 100px;
}

.fade_rule {
	margin-bottom: 1rem;
	height: 1px;
	background-image: linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
	background-image: -o-linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
	background-image: -moz-linear-gradient( left, white 2%, #e6e6e6 50%, white 98% );
	background-image: -webkit-linear-gradient( left, white 2%, #e6e6e6 50%, white 98% );
	background-image: -ms-linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
	background-image: -webkit-gradient( linear, left bottom, right bottom, color-stop(0.02, white), color-stop(0.5, gray), color-stop(0.98, white) );
}

.loader {
	width: 48px;
	height: 48px;
	border: 5px solid #fff;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.save-quote .loader {
	width: 20px;
	height: 20px;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
