/* ### DEFAULT VALUES - XS ### */

footer {
    background-color: black;
    color: white;
}

.footer {
    
    padding-top: 1rem;
    padding-bottom: 1rem;
}
    .footer .footer-links {
        padding-bottom: 1rem;
    }
    .footer .nav a {
        padding-left: 0;
        padding-right: 2rem;
    }
    .footer img {
        max-width: 175px;
    }
    .footer .copywrite {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .footer {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .footer .nav a {
        padding: var(--bs-nav-link-padding-y) 0;
    }
    .footer img {
        max-width: 200px;
    }
    footer .legal-footer {
        border-left: 2px solid lightgrey;
        padding-left: 2rem;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}