/* ### DEFAULT VALUES - XS ### */

.layout-page .header-container {
    padding-bottom: 2rem;
}
.layout-page .header-container .header-text {
    text-align: center;
    font-size: 1.75rem;
    font-weight: 700;
}
.layout-page .site-name {
    text-align: center;
    font-size: 1rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .layout-page .header-container {        
        padding-bottom: 2.5rem;
    }
    .layout-page .site-name {
        text-align: left;
        margin-bottom: 2rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
