/* ### DEFAULT VALUES - XS ### */

.layout-header {
    background-color: white;
    min-height: 110px;
}

.layout-header .brand-img {
    max-height: 50px;
}

.layout-header .contact-details .tel-link {
    color: #3ea736;
    margin-left: 0.25rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    
    .layout-header .brand-img {
        max-height: 60px;
    }

    .layout-header .nav-left,
    .layout-header .nav-center,
    .layout-header .nav-right {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}