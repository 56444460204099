/* ### DEFAULT VALUES - XS ### */

.header-sticky-inner {
    z-index: 99999;
}

.layout-page .header-sticky-active .header-container {
    /* Remove the bottom padding when it's sticky so it doesn't cover the content on the page */
    padding-bottom: 0; 
}

/* ANIMATION */
.header-sticky .header-container {
    animation: price-standard-start 0.5s forwards;
}

.header-sticky-active .header-container {
    animation: price-sticky-start 0.5s forwards;
}

@keyframes price-sticky-start {
    0% { transform: scale(1.1, 1.1); }
    100% { transform: scale(1, 1); }
}

@keyframes price-standard-start {
    0% { transform: scale(0.8, 0.8); }
    100% { transform: scale(1, 1); }
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}