/* ### DEFAULT VALUES - XS ### */

.panel {
    
}

.panel > .card-header { 
    color: #5d257c;
}

.panel-section .section-heading .underline {
    border-bottom-color: #3EA736;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .panel > .card-body {
        
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
