@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ltn26t');
  src:  url('fonts/icomoon.eot?ltn26t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ltn26t') format('truetype'),
    url('fonts/icomoon.woff?ltn26t') format('woff'),
    url('fonts/icomoon.svg?ltn26t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-error:before {
  content: "\e957";
}
.icon-updated:before {
  content: "\e959";
}
.icon-session-expired:before {
  content: "\e95b";
}
.icon-maintenance:before {
  content: "\e95c";
}
.icon-information:before {
  content: "\e95d";
}
.icon-drivers1:before {
  content: "\e955";
}
.icon-house:before {
  content: "\e953";
}
.icon-mileage:before {
  content: "\e954";
}
.icon-calendar-sync:before {
  content: "\e952";
}
.icon-helping-hands:before {
  content: "\e951";
}
.icon-exclamation-triangle:before {
  content: "\e950";
}
.icon-clock:before {
  content: "\e94f";
}
.icon-sos:before {
  content: "\e94d";
}
.icon-envelope-open-line:before {
  content: "\e949";
}
.icon-repairing:before {
  content: "\e945";
}
.icon-hand:before {
  content: "\e946";
}
.icon-upgrade-update:before {
  content: "\e947";
}
.icon-time-limit:before {
  content: "\e948";
}
.icon-manage-account:before {
  content: "\e944";
}
.icon-red-x:before {
  content: "\e942";
  color: #ff4141;
}
.icon-circle-x-fill:before {
  content: "\e94b";
}
.icon-green-checkmark:before {
  content: "\e943";
  color: #6bbe66;
}
.icon-circle-check-fill:before {
  content: "\e94c";
}
.icon-manual-information-file:before {
  content: "\e941";
}
.icon-document-download:before {
  content: "\e93e";
}
.icon-document-export:before {
  content: "\e93f";
}
.icon-file-upload:before {
  content: "\e940";
}
.icon-circle-cross:before {
  content: "\e93b";
}
.icon-circle-check:before {
  content: "\e93c";
}
.icon-display:before {
  content: "\e956";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-tablet:before {
  content: "\e95a";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-cookie:before {
  content: "\e92d";
}
.icon-telematics-van-car3:before {
  content: "\e928";
}
.icon-complaint:before {
  content: "\e92c";
}
.icon-telematics-van-car2:before {
  content: "\e92b";
}
.icon-telematics-van-car:before {
  content: "\e92a";
}
.icon-telematics-car-van:before {
  content: "\e929";
}
.icon-complaint1:before {
  content: "\e92e";
}
.icon-hands:before {
  content: "\e912";
}
.icon-fb-badge:before {
  content: "\e913";
}
.icon-ui-checks:before {
  content: "\e914";
}
.icon-people-fill:before {
  content: "\e915";
}
.icon-people:before {
  content: "\e916";
}
.icon-file-earmark-text:before {
  content: "\e917";
}
.icon-shield-check:before {
  content: "\e918";
}
.icon-lock:before {
  content: "\e919";
}
.icon-shield-lock:before {
  content: "\e91a";
}
.icon-exclamation-lg:before {
  content: "\e91b";
}
.icon-exclamation-square:before {
  content: "\e91c";
}
.icon-laptop:before {
  content: "\e91d";
}
.icon-bag-plus:before {
  content: "\e91e";
}
.icon-bag-plus-fill:before {
  content: "\e91f";
}
.icon-bag-check:before {
  content: "\e920";
}
.icon-bag-check-fill:before {
  content: "\e921";
}
.icon-hand-thumbs-up-fill:before {
  content: "\e922";
}
.icon-clipboard-check:before {
  content: "\e923";
}
.icon-telephone-fill:before {
  content: "\e924";
}
.icon-question-lg:before {
  content: "\e925";
}
.icon-question:before {
  content: "\e926";
}
.icon-eurostars:before {
  content: "\e927";
}
.icon-menu-down-arrow:before {
  content: "\e9bf";
}
.icon-menu-up-arrow:before {
  content: "\e9c0";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-policytype-gap2:before {
  content: "\e94e";
}
.icon-policytype-car2:before {
  content: "\e94a";
}
.icon-car-side:before {
  content: "\e93d";
}
.icon-policytype-tele-van:before {
  content: "\e90d";
}
.icon-policytype-car:before {
  content: "\e909";
}
.icon-policytype-gap:before {
  content: "\e90a";
}
.icon-policytype-tele:before {
  content: "\e90c";
}
.icon-policytype-trade:before {
  content: "\e910";
}
.icon-policytype-van:before {
  content: "\e911";
}
.icon-policytype-home:before {
  content: "\e90e";
}
.icon-policytype-pet:before {
  content: "\e90f";
}
.icon-policytype-pl:before {
  content: "\e90b";
}
.icon-drops:before {
  content: "\e936";
}
.icon-drop-br:before {
  content: "\e937";
}
.icon-drop-right:before {
  content: "\e938";
}
.icon-opex-SCRATCH:before {
  content: "\e939";
}
.icon-opex-BD:before {
  content: "\e900";
}
.icon-opex-XS:before {
  content: "\e901";
}
.icon-opex-GOODS:before {
  content: "\e902";
}
.icon-opex-KEY:before {
  content: "\e903";
}
.icon-opex-LEGAL:before {
  content: "\e904";
}
.icon-opex-LD:before {
  content: "\e905";
}
.icon-opex-RPL:before {
  content: "\e906";
}
.icon-opex-TOOLS:before {
  content: "\e907";
}
.icon-opex-WS:before {
  content: "\e908";
}
.icon-st-opex-SCRATCH:before {
  content: "\e93a";
}
.icon-st-opex-BABY:before {
  content: "\e933";
}
.icon-st-opex-BD:before {
  content: "\e92f";
}
.icon-st-opex-XS:before {
  content: "\e930";
}
.icon-st-opex-KEY:before {
  content: "\e931";
}
.icon-st-opex-LEGAL:before {
  content: "\e932";
}
.icon-st-opex-RPL:before {
  content: "\e934";
}
.icon-st-opex-WS:before {
  content: "\e935";
}
