/* ### DEFAULT VALUES - XS ### */

.display-item {
    margin-bottom: 0.25rem;
}

.display-item.car-reg {
    margin-bottom: 0.5rem;
}

.display-item .data-label {
    font-size: 0.875rem;
    font-weight: 500;
}

.display-item.d-flex .data-label {
    min-width: 135px;
}

.display-item .data-label:not(.hz-mb) {
    margin-bottom: 0.5rem;
}

.display-item .data-value {
    overflow-wrap: anywhere;
    font-size: 0.875rem;
}

.display-item .data-value:not(.hz-mb) {
    margin-bottom: 1rem;
}

.display-item .data-value:last-child {
    margin-bottom: 0;
}

.display-item .info-box {
    margin-top: 0;
}

.display-item .section-heading {
    margin-bottom: 0.75rem;
}

.display-item .data-value .precis {
    margin-bottom: 0.75rem;
}

.display-item .data-value .precis:last-child {
    margin-bottom: 0;
}


.display-item .data-value h4 {
    font-size: 1rem;
}

.display-item .data-value p {
    margin-bottom: 0.5rem;
}

.display-item .data-value br {
    display: none;
}

.display-item .data-value br+p {
    font-weight: bold;
}

.display-item .data-value .precis-heading {
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.display-item .data-value .precis-text:not(:last-child) {
    margin-bottom: 0.5rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .display-item.d-flex .data-label {
        min-width: 140px;
    }
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    
    .display-item .data-label {
        font-size: 1rem;
    }
    .display-item.d-flex .data-label {
        min-width: 160px;
    }

    .display-item .data-value {
        font-size: 1rem;
    }
    .display-item .data-value h4 {
        font-size: 1.1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .display-item.d-flex .data-label {
        min-width: 175px;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
