/* ### DEFAULT VALUES - XS ### */

.price-container .price {
	font-size: 1.25rem;
	font-weight: 700;
}
.price-container .price span {
	font-weight: 700;
}
.price-container .price .pound {
	font-size: 4.5rem;
	font-weight: 900;
}

.price-container .price-info {
	color: #000000;
	text-align: center;
	padding: 0.25rem 0;
	font-size: 0.875rem;
}

.price-container .price-separator {
	font-weight: 600;
	font-size: 1.5rem;
}

/* .price-container .card-header {
	background-color: red !important;
} */

.price-card {
	border: none;
	background-color: transparent;
}

/* .price-card .card-header,
.price-card .price-separator {

} */

/* STICKY HEADER */

.header-sticky-active .price-container .price {
	font-size: 1.25rem;
	font-weight: 700;
}
.header-sticky-active .price-container .price .pound {
	font-size: 3.5rem;
	font-weight: 900;
}
.header-sticky-active .price-container .price-info {
	padding-bottom: 1rem;
}

/* Hide non-selected price when sticky header is active */
.header-sticky-active .header-text,
.header-sticky-active .price-divider,
.header-sticky-active .ipt-statement {
	display: none;
}

/* END STICKY HEADER */

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
	.price-card {
		height: 100%;
	}

	.price-container .price-separator {
		height: 120px;
	}
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
	.price-card.monthly {
		height: auto;
	}
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
