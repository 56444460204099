/* ### DEFAULT VALUES - XS ### */

.finance-provider {
    font-size: 0.875rem;
}
.finance-provider address {
    margin-top: 0.5rem;
    color: #333;
    margin-right: 1.5rem;
}
.finance-provider address .provider-name {
    font-weight: 400;
    color: #000;
}
.finance-provider img {
    max-width: 150px;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .finance-provider address {
        margin-right: 2rem;
    }
    .finance-provider img {
        max-width: 200px;
    }
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
