
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #3EA736;
    --bs-btn-border-color: #3EA736;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #3EA736;
    --bs-btn-disabled-border-color: #3EA736;
    --bs-btn-font-weight: 500;
}
.btn-primary .animated-pulse span {
    background-color: #fff;
}

/*
.btn-outline-primary {
    --bs-btn-color: #7975be;
    --bs-btn-border-color: #7975be;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #7975be;
    --bs-btn-hover-border-color: #7975be;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #7975be;
    --bs-btn-active-border-color: #7975be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #7975be;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #7975be;
    --bs-gradient: none;
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #7975be;
    --bs-btn-border-color: #7975be;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgba(57, 4, 138, 0.4392156863);
    --bs-btn-hover-border-color: rgba(57, 4, 138, 0.4392156863);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #7975be;
    --bs-btn-disabled-border-color: #7975be;
}*/

.btn-outline-secondary {
    --bs-btn-color: #000;
    --bs-btn-border-color: #ccc;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #EEE9F2;
    --bs-btn-hover-border-color: #5d257c;
    --bs-btn-focus-shadow-rgb: 121, 117, 190;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #5d257c;
    --bs-btn-active-border-color: #5d257c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #ccc;
    --bs-btn-disabled-border-color: #ccc;
    --bs-gradient: none;
}
/* This is needed here, as the override to grey border / black text above pulls through on :hover state without it */
.btn-outline-secondary:hover {
    --bs-btn-color: #000;
    --bs-btn-bg: #EEE9F2;
    --bs-btn-border-color: #5d257c;
}


