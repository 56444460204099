@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,900");

/* ** apply the font to the entire site ** */
* {
    font-family: "Montserrat", sans-serif;
}

body {
    background-color: white; /* NOTE: The COOKIE banner (which is OUTSIDE the LAYOUT) needs to have a white background */
}
