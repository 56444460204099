/* The values below match up with the font weight names in Pencil */

/* ### DEFAULT VALUES - XS ### */

.fw-thin {
    font-weight: 200;
}

.fw-light {
    font-weight: 300;
}

.fw-regular {
    font-weight: 400;
}

.fw-medium {
    font-weight: 500;
}

.fw-semibold {
    font-weight: 600;
}

.fw-bold {
    font-weight: 700;
}

.fw-extra-bold {
    font-weight: 800;
}

.fw-black {
    font-weight: 900;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}