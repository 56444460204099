/* ### DEFAULT VALUES - XS ### */

.quote-header-details .btn-link {
	text-decoration: none;
}

.quote-header-details .btn-link:hover {
	text-decoration: underline;
}


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
	
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
	
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
