/* ### DEFAULT VALUES - XS ### */

a {
    text-decoration: none;
    font-weight: 400;
}
a:hover {
    text-decoration: underline;
}
a:focus-visible {
    text-decoration: underline;
    outline: none;
}

.text-danger .tel-link {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
