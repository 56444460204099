/* ### DEFAULT VALUES - XS ### */

.alert.dd-feedback {
    --bs-alert-padding-y: 0.5rem;
    font-weight: 500;
    text-align: center;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
