/* ### DEFAULT VALUES - XS ### */

.btn.btn-icon .icon {
    font-size: 3rem;
}

.btn-link-inline {
    display: inline;
    padding: 0;
    vertical-align: baseline;
    text-align: left;
}
.btn.loading {
    min-height: 38px;
}

.btn-link {
    text-decoration: none;
}
.btn-link:hover {
    text-decoration: underline;
}
.btn-link:focus-visible {
    box-shadow: none;
    text-decoration: underline;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
